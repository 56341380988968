import { Model } from "@vuex-orm/core";
import http, {getToken} from "@/services";

export default class Frequency extends Model {
    static entity = 'frequencies';

    static fields() {
        return {
            data: this.attr({}),
            meta: this.attr({})
        }
    }

    static async getFrequencies(pag = 1, filters) {
        await this.api()
            .get(`/frequency?page=${pag || 1}`, {
                headers: {
                    Authorization: `Bearer ${ getToken() }`
                },
                params: {
                    filters
                }
            })
            .then(async () => {
                let result = this.query().all();
                if (result.length > 1) {
                    let first = this.query().first();
                    await this.delete(first.$id);
                }
            });
    }

    static async getFrequencyById(id) {
        const { data } = await http.get(`/frequency/${id}`, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        });

        return data;
    }

    static async saveFrequency(data) {
        await http.post('/frequency', { data }, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        });
    }

    static async updateFrequency(data, id) {
        await http.put(`/frequency/${id}/update`, { data }, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        });
    }

    static async deleteFrequency(id) {
        await http.delete(`/frequency/${id}/delete`, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        })
    }

    static async getFrequenciesForValidation(pag = 1, filters) {
        await this.api()
            .get(`/validate-frequency?page=${pag || 1}`, {
                headers: {
                    Authorization: `Bearer ${ getToken() }`
                },
                params: {
                    filters
                }
            })
    }

    static async updateValidationFrequency(data, id) {
        await http.put(`/validate-frequency/${id}/update`, { data }, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        });
    }
}