import { defineStore } from "pinia";
import http, { getToken } from "@/services";

const currentYear = new Date().getFullYear();

if (localStorage.getItem("selectedYear")) {
  localStorage.removeItem("selectedYear");
  localStorage.setItem("selectedYear", currentYear);
}

const yearOptionsStorageName = "yearOptions";

export const useYearStore = defineStore("year", {
  state() {
    return {
      yearOptions: [],
      selectedYear: currentYear,
    };
  },
  actions: {
    async getLastYears() {
      if (!this.getYearOptions()) {
        let { data } = await http.get('/school/last-years', {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        });
        this.yearOptions = data;
        this.setYearOptions();
      } else this.yearOptions = this.getYearOptions();
    },
    setYearOptions() {
      localStorage.setItem(
        yearOptionsStorageName,
        JSON.stringify(this.yearOptions)
      );
    },
    getYearOptions() {
      return JSON.parse(localStorage.getItem(yearOptionsStorageName));
    },
    async setSelectedYear(year) {
      if (!year) year = currentYear;
      return await this.saveYearSession(year).then(() => {
        this.selectedYear = parseInt(year);
        localStorage.setItem("selectedYear", year);
      });
    },
    getSelectedYear() {
      return localStorage.getItem("selectedYear");
    },
    async saveYearSession(year = currentYear) {
      await http.post(
        "set-year-session",
        {
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
    },
  },
});
