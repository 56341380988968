export default [
  {
    path: "/app/teachers",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Professor",
      requiresAuth: true,
    },
    children: [
      /* AEE */
      {
        path: "aee/planning",
        name: "teacher.aee.planning",
        component: () => import("../aee/planning/views/List"),
      },
      {
        path: "aee/planning/create",
        name: "teacher.aee.planning.create",
        component: () => import("../aee/planning/views/Create"),
      },
      {
        path: "aee/planning/edit/:id",
        name: "teacher.aee.planning.edit",
        component: () => import("../aee/planning/views/Edit"),
      },
      {
        path: "aee/planning/copy/:id",
        name: "teacher.aee.planning.copy",
        component: () => import("../aee/planning/views/Copy"),
      },
      {
        path: "aee/planning/show/:id",
        name: "teacher.aee.planning.show",
        component: () => import("../aee/planning/views/Show"),
      },
      //----------------------------RECORD-AEE----------------------------
      {
        path: "aee/record-aee",
        name: "teacher.aee.record-aee",
        component: () => import("../aee/record-aee/views/List"),
      },
      {
        path: "aee/record-aee/create",
        name: "teacher.aee.record-aee.create",
        component: () => import("../aee/record-aee/views/Create"),
      },
      {
        path: "aee/record-aee/edit/:id",
        name: "teacher.aee.record-aee.edit",
        component: () => import("../aee/record-aee/views/Edit"),
      },
      {
        path: "aee/record-aee/show/:id",
        name: "teacher.aee.record-aee.show",
        component: () => import("../aee/record-aee/views/Show"),
      },
      //----------------------------SERVICE-AEE----------------------------
      {
        path: "aee/service-aee",
        name: "teacher.aee.service-aee",
        component: () => import("../aee/service-aee/views/List"),
      },
      {
        path: "aee/service-aee/create",
        name: "teacher.aee.service-aee.create",
        component: () => import("../aee/service-aee/views/Create"),
      },
      {
        path: "aee/service-aee/edit/:id/:registration",
        name: "teacher.aee.service-aee.edit",
        component: () => import("../aee/service-aee/views/Edit"),
      },
      {
        path: "aee/service-aee/show/:id",
        name: "teacher.aee.service-aee.show",
        component: () => import("../aee/service-aee/views/Show"),
      },
      /* /AEE */

      /* Activities */
      //----------------------------ACTIVITY REGISTER----------------------------
      {
        path: "activity-register",
        name: "teacher.activity-register",
        component: () => import("../activity/activity-register/views/List"),
      },
      {
        path: "activity-register/create",
        name: "teacher.activity-register.create",
        component: () => import("../activity/activity-register/views/Create"),
      },
      {
        path: "activity-register/edit/:id",
        name: "teacher.activity-register.edit",
        component: () => import("../activity/activity-register/views/Edit"),
      },
      //----------------------------ACTIVITY NOTE----------------------------
      {
        path: "activity-note",
        name: "teacher.activity-note.create",
        component: () => import("../activity/activity-note/views/List"),
      },
      /* Activities */

      /* Class Diary */
      //----------------------------FREQUENCY----------------------------

      {
        path: "frequencies",
        name: "teacher.frequencies",
        component: () => import("../frequency/views/List"),
      },
      {
        path: "frequencies/create/:cod_turma?/:week_day?",
        name: "teacher.frequencies.create",
        component: () => import("../frequency/views/Create.vue"),
        meta: {
          name: "Frequencia - Criar",
        },
      },
      {
        path: "frequencies/edit/:id",
        name: "teacher.frequencies.edit",
        component: () => import("../frequency/views/Edit.vue"),
      },
      {
        path: "frequencies/show/:id",
        name: "teacher.frequencies.show",
        component: () => import("../frequency/views/Show.vue"),
      },
      {
        path: "frequencies/class-map",
        name: "teacher.frequencies.class-map",
        component: () => import("../class-map/views/ClassMap.vue"),
      },
      //----------------------------NOTES----------------------------
      {
        path: "notes",
        name: "teacher.notes",
        component: () => import("../notes/views/List"),
      },
      {
        path: "notes/create",
        name: "teacher.notes.create",
        component: () => import("../notes/views/List"),
      },
      //----------------------------CLASS REPLACEMENT----------------------------
      {
        path: "class-replacement",
        name: "teacher.class-replacement",
        component: () => import("../class-replacement/views/List.vue"),
      },
      /* Class Diary */

      /* Occurrence Disciplinary */
      {
        path: "registration-occurrence-disciplinary",
        name: "teacher.registration.occurrence.disciplinary",
        component: () => import("../occurrence-disciplinary/views/List"),
      },
      {
        path: "registration-occurrence-disciplinary/create",
        name: "teacher.registration.occurrence.disciplinary.create",
        component: () => import("../occurrence-disciplinary/views/Create"),
      },
      {
        path: "registration-occurrence-disciplinary/edit/:id",
        name: "teacher.registration.occurrence.disciplinary.edit",
        component: () => import("../occurrence-disciplinary/views/Edit"),
      },
      /* /Occurrence Disciplinary */

      /* Planning */
      {
        path: "planning",
        name: "teacher.planning",
        component: () => import("../planning/views/List"),
      },
      {
        path: "planning/create",
        name: "teacher.planning.create",
        component: () => import("../planning/views/Create"),
      },
      {
        path: "planning/edit/:id",
        name: "teacher.planning.edit",
        component: () => import("../planning/views/Edit"),
      },
      {
        path: "planning/copy/:id",
        name: "teacher.planning.copy",
        component: () => import("../planning/views/Copy"),
      },
      {
        path: "planning/show/:id",
        name: "teacher.planning.show",
        component: () => import("../planning/views/Show"),
      },
      /* /Planning */
    ],
  },
];
