export default [
  {
    path: "/app/instructions",
    component: () => import("../views/index.vue"),
    meta: {
      title: "Educasis - Instruções",
      requiresAuth: true
    },
    children: [
      {
        path: "instructions",
        name: "instructions.list",
        component: () => import("../views/List.vue")
      },
      {
        path: "create",
        name: "instructions.create",
        component: () => import("../views/Create.vue")
      }
    ]
  }
];
