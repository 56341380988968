<template>
  <div
    v-if="!user.is_teacher"
    class="mx-1 d-flex justify-content-center align-items-center mt-1 icon-news"
  >
    <router-link :to="{ name: 'news.list' }" @click="clearNotification">
      <i v-if="showNotification" class="fas fa-info-circle"></i>&nbsp;
      <span class="novidades-text text-info mb-0">Novidades</span>
      <i
        class="fas fa-external-link-alt text-info mx-2 mt-1"
        title="Novidades"
      ></i>
    </router-link>
  </div>

  <li class="nav-item m-auto">
    <ul class="list-inline-group year-school-ul">
      <span class="text-secondary year-school">Ano letivo</span
      >&nbsp;
      <li class="list-inline-item">
        <div class="form-group amount-spent-select">
          <select
            v-model="yearStore.selectedYear"
            class="form-control form-control-sm form-select"
            @change="onChangeYear"
          >
            <template
              v-for="(year, index) in yearStore.yearOptions"
              :key="index"
            >
              <option :value="year.id">
                {{ year.text }}
              </option>
            </template>
          </select>
        </div>
      </li>
    </ul>
  </li>

  <!-- Notification -->
  <notification />
  <!-- /Notification -->

  <!-- User Menu -->

  <span
    class=".nav-item-user text-secondary font-span d-flex justify-content-center align-items-center"
  >
    {{ user.name }}
  </span>

  <li class="nav-item">
    <router-link to="/" class="nav-link text-danger" @click="logout">
      <i class="fas fa-power-off" style="font-size: 1rem" />
      Sair
    </router-link>
  </li>
  <!--  <li class="nav-item dropdown has-arrow">-->
  <!--    <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">-->
  <!--      <span>{{ user.name }} - <span class="text-muted mb-0">{{ user.role_name }}</span></span>-->
  <!--    </a>-->
  <!--    <div class="dropdown-menu">-->
  <!--      <div class="user-header">-->
  <!--        <div class="user-text">-->
  <!--          <span>{{ user.name }}</span>-->
  <!--          <p class="text-muted mb-0">{{ user.role_name }}</p>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <router-link class="dropdown-item" to="/profile">My Profile</router-link>-->
  <!--      <router-link class="dropdown-item" to="/inbox">Inbox</router-link>-->
  <!--      <router-link class="dropdown-item" @click="logout" to="/">Logout</router-link>-->
  <!--    </div>-->
  <!--  </li>-->
  <!-- /User Menu -->
</template>

<script>
import Auth from "@/modules/auth/models/Auth";
import User from "@/modules/auth/models/User";
import Notification from "@/components/notification";
import { useYearStore } from "../store/year";
import { useNotificationNewsStore } from "../store/notificationNews";
import { useNewsStore } from "../modules/news/store/news";

export default {
  name: "UserMenu",
  components: {
    Notification
  },
  data: () => ({
    user: User.query().first(),
    yearStore: useYearStore(),
    notificationStore: useNotificationNewsStore(),
    newsStore: useNewsStore()
  }),
  async mounted() {
    await this.yearStore.getLastYears();
    window.Echo.channel("news").listen("NewsCreated", e => {
      this.notificationStore.setShowNotification(true);
    });
  },
  methods: {
    logout() {
      Auth.logout();
    },
    async onChangeYear({ target }) {
      await this.yearStore.setSelectedYear(target.value);
    },
    clearNotification() {
      this.notificationStore.setShowNotification(false);
    }
  },
  computed: {
    showNotification() {
      return this.notificationStore.showNotification;
    }
  }
};
</script>

<style scoped>
.novidades-text,
i {
  font-size: 15px;
}

@media (max-width: 575px) {
  .font-span {
    display: none !important;
  }
}

@media (max-width: 689px) {
  .novidades-text {
    display: none;
  }

  .icon-news {
    margin-right: 5px !important;
  }
}

@media (max-width: 689px) {
  .novidades-text {
    display: none;
  }

  .icon-news {
    margin-right: 5px !important;
  }
}

.font-span {
  font-size: 14px !important;
}
</style>
