export default [
  {
    path: "/app/people",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Pessoas",
      requiresAuth: true,
    },
    children: [
      //----------------------------NATURAL-PERSON----------------------------
      {
        path: "natural-person",
        name: "people.natural-person",
        component: () => import("../natural-person/views/List"),
      },
      {
        path: "natural-person/create",
        name: "people.natural-person.create",
        component: () => import("../natural-person/views/Create"),
      },
      {
        path: "natural-person/show/:id",
        name: "people.natural-person.show",
        component: () => import("../natural-person/views/Show"),
      },
      {
        path: "natural-person/edit/:id",
        name: "people.natural-person.edit",
        component: () => import("../natural-person/views/Edit"),
      },

      //----------------------------LEGAL-PERSON----------------------------

      {
        path: "legal-person",
        name: "people.legal-person",
        component: () => import("../legal-person/views/List"),
      },
      {
        path: "legal-person/create",
        name: "people.legal-person.create",
        component: () => import("../legal-person/views/Create"),
      },
      {
        path: "legal-person/show/:id",
        name: "people.legal-person.show",
        component: () => import("../legal-person/views/Show"),
      },
      {
        path: "legal-person/edit/:id",
        name: "people.legal-person.edit",
        component: () => import("../legal-person/views/Edit"),
      },

      //----------------------------COLOR-RACE----------------------------
      {
        path: "color-race",
        name: "people.color-race",
        component: () => import("../type/color-race/views/List"),
      },
      {
        path: "color-race/create",
        name: "people.color-race.create",
        component: () => import("../type/color-race/views/Create"),
      },
      {
        path: "color-race/edit/:id",
        name: "people.color-race.edit",
        component: () => import("../type/color-race/views/Edit"),
      },
      //----------------------------DEFICIENCY----------------------------
      {
        path: "deficiency",
        name: "people.deficiency",
        component: () => import("../type/deficiency/views/List"),
      },
      {
        path: "deficiency/create",
        name: "people.deficiency.create",
        component: () => import("../type/deficiency/views/Create"),
      },
      {
        path: "deficiency/edit/:id",
        name: "people.deficiency.edit",
        component: () => import("../type/deficiency/views/Edit"),
      },
      //----------------------------RELIGION----------------------------
      {
        path: "religion",
        name: "people.religion",
        component: () => import("../type/religion/views/List"),
      },
      {
        path: "religion/create",
        name: "people.religion.create",
        component: () => import("../type/religion/views/Create"),
      },
      {
        path: "religion/edit/:id",
        name: "people.religion.edit",
        component: () => import("../type/religion/views/Edit"),
      },
    ],
  },
];
