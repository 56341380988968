import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class LessonPlanningAee extends Model {
  static entity = 'lessons-planning-aee';
  
  static fields() {
    return {
      data: this.attr({}),
      meta: this.attr({})
    }
  }
  
  static async getLessonsPlanningAee(pag = 1, filters) {
    await this.api()
      .get(`/lesson-planning-aee?page=${pag || 1}`, {
        headers: {
          Authorization: `Bearer ${ getToken() }`
        },
        params: {
          filters
        }
      })
      .then(async () => {
        let result = this.query().all();
        if (result.length > 1) {
          let first = this.query().first();
          await this.delete(first.$id);
        }
      });
  }
  
  static async saveLessonPlanningAee(data) {
    await http.post('lesson-planning-aee', { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    })
  }
  
  static async updateLessonPlanningAee(data, id) {
    await http.put(`lesson-planning-aee/${id}`, { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    })
  }
  
  static async getLessonPlanningAeeById(id) {
    const { data } = await http.get(`lesson-planning-aee/${id}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
    
    return data.data;
  }
  
  static async deleteLessonPlanningAee(id) {
    const result = await http.delete(`lesson-planning-aee/${id}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    })
  }

  static async getValidateLessonsPlanningAee(pag = 1, filters) {
    await this.api()
      .get(`validate-lesson-planning-aee?page=${pag || 1}`, {
        headers: {
          Authorization: `Bearer ${ getToken() }`
        },
        params: {
          filters
        }
      })
  }

  static async updateValidationLessonsPlanningAee(data, id) {
    await http.put(`validate-lesson-planning-aee/${id}/update`, { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
  })
  }
}
