import { Model } from "@vuex-orm/core";
import { getToken } from "@/services";
import axios from "axios";
require("regenerator-runtime/runtime");

export default class Instance extends Model {
  static entity = "instance";

  constructor() {
    super();
    this.connection = {
      valid: false,
      host: null,
      globalApiKey: null,
    };

    this.instancesList = [];
    this.connectionsList = [];
  }

  static fields() {
    return {
      connection: this.attr(),
    };
  }

  async setConnection({ host, globalApiKey }) {
    try {
      const apiResponse = await axios({
        method: "GET",
        baseURL: host,
        headers: {
          "Content-Type": "application/json",
          apiKey: globalApiKey,
        },
        url: "/",
      });

      if (
        !apiResponse.data ||
        !apiResponse.data.message ||
        !apiResponse.data.message.includes("Evolution API")
      ) {
        throw new Error("Essa conexão não é uma instância da evolution-api");
      }

      const { version } = apiResponse;

      const response = await axios({
        method: "GET",
        baseURL: host,
        headers: {
          "Content-Type": "application/json",
          apiKey: globalApiKey,
        },
        url: "/instance/fetchInstances",
      });

      this.saveConnection({ host, globalApiKey, version });
      this.instancesList = response.data;
    } catch (e) {
      this.connection.valid = false;
      throw e.response?.data?.response?.message || e.response || e;
    }
  }

  validConnection() {
    console.log("Válido: " + this.connection.valid);
    return this.connection.valid;
  }

  saveConnection({ host, globalApiKey, version }) {
    this.connection = {
      valid: true,
      host,
      globalApiKey,
      version,
    };

    const currentKey = this.connectionsList.findIndex(
      (item) => item.host === host
    );
    if (currentKey === -1) {
      this.connectionsList.push({ host, globalApiKey });
    } else {
      this.connectionsList[currentKey] = { host, globalApiKey };
    }

    this.saveLocalStorage();
  }

  saveLocalStorage() {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        "connection",
        JSON.stringify(this.connection)
      );
      window.localStorage.setItem(
        "connectionsList",
        JSON.stringify(this.connectionsList)
      );
    }
  }
}
