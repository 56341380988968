let user = {};

const setUser = (newUser) => {
    user = newUser;
}

const menus = [
    {
        mainMenu: 'Coordenação',
        icon: 'fas fa-users',
        submenu: true,
        submenuItems: [
            {
                label: 'Validação',
                icon: 'fas fa-thumbs-up',
                submenu: true,
                can_show: user?.permissions?.view_validate_lesson_planning,
                submenuItems: [
                    {
                        link: 'coordination.validate-lesson-planning',
                        label: 'Plano de Aula'
                    },
                    {
                        link: 'coordination.validate-frequency',
                        label: 'Frequência'
                    },
                    {
                        link: 'coordination.validate-lesson-planning-aee',
                        label: 'Plano de Aula AEE'
                    },
                    {
                        link: 'coordination.validate-service-aee',
                        label: 'Atendimento AEE'
                    }
                ]
            },
            {
                label: 'Falta/Atraso',
                icon: 'fas fa-chalkboard',
                link: 'coordination.absent-delay',
                submenu: false,
            },
            {
                label: 'Reposição de Aulas',
                icon: 'fas fa-chalkboard',
                link: 'coordination.class-replacement',
                submenu: false,
            }
        ]
    },
    {
        mainMenu: 'Professores',
        icon: 'fas fa-chalkboard-teacher',
        submenu: true,
        submenuItems: [
            {
                label: 'AEE',
                icon: 'fas fa-chalkboard-teacher',
                submenu: true,
                submenuItems: [
                    {
                        link: 'teacher.aee.planning',
                        label: 'Plano de Aula AEE'
                    },
                    {
                        link: 'teacher.aee.record-aee',
                        label: 'Ficha AEE'
                    },
                    {
                        link: 'teacher.aee.service-aee',
                        label: 'Atendimento AEE'
                    }
                ]
            },
            {
                label: 'Atividades',
                icon: 'fas fa-pencil-alt',
                submenu: true,
                submenuItems: [
                    {
                        link: 'teacher.activity-register',
                        label: 'Atividades da Turma'
                    },
                    {
                        link: 'teacher.activity-note.create',
                        label: 'Nota das Atividades'
                    },
                ]
            },
            {
                label: 'Diário de Classe',
                icon: 'fas fa-book',
                submenu: true,
                submenuItems: [
                    {
                        link: 'teacher.frequencies',
                        label: 'Frequência',
                        can: 'view_frequency'
                    },
                    {
                        link: 'teacher.notes',
                        label: 'Notas'
                    },
                    {
                        link: 'teacher.class-replacement',
                        label: 'Solicitar Reposição'
                    }
                ]
            },
            {
                label: 'Ocorrência',
                icon: 'fas fa-exclamation-triangle',
                link: 'teacher.registration.occurrence.disciplinary',
                submenu: false,
            },
            {
                label: 'Plano de Aula',
                icon: 'fas fa-chalkboard',
                link: 'teacher.planning',
                submenu: false,
            },
        ]
    },
    {
        mainMenu: 'Relatórios',
        icon: 'fas fa-file',
        submenu: true,
        submenuItems: [
            {
                label: 'Professor',
                icon: 'fas fa-chalkboard-teacher',
                submenu: true,
                submenuItems: [
                    {
                        label: 'Boletim',
                        link: 'reports.teachers-bulletin',
                        submenu: false,
                    },
                    {
                        label: 'Alunos por Turma',
                        link: 'reports.students-per-class',
                        submenu: false
                    }
                ]
            }
        ]
    }
];

export { menus, setUser };