import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class RecordAee extends Model {
  static entity = 'record_aee';

  static fields() {
    return {
      data: this.attr({}),
      meta: this.attr({})
    }
  }

  static async getRecordAee(pag = 1, filters) {
    await this.api().get(`/record-aee?page=${pag || 1}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      },
      params: {
        filters
      }
    })
  }

  static async saveRecordAee(data) {
    await http.post('/record-aee', { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
  }  

  static async getRecordAeeById(id) {
    const { data } = await http.get(`/record-aee/${id}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
    return data.data;
}

  static async updateRecordAee(id, data) {
    await http.put(`/record-aee/${id}/update`, { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
}

  static async deleteRecordAee(id) {
    await http.delete(`/record-aee/${id}/delete`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    })
  }
}