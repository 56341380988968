import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class AbsentDelayTime extends Model {
    static entity = 'absent-delay-time';

    static fields() {
        return {
            data: this.attr({}),
            meta: this.attr({})
        }
    }

    static async getAbsentDelayTime(pag = 1, filters) {
        await this.api()
            .get(`/absent-delay-time?page=${pag || 1}`, {
                headers: {
                    Authorization: `Bearer ${ getToken() }`
                },
                params: {
                    filters
                }
            })
            .then(async () => {
                let result = this.query().all();
                if (result.length > 1) {
                    let first = this.query().first();
                    await this.delete(first.$id);
                }
            });
    }
}
