export default [
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login"),
    meta: {
      title: "Educasis - Login"
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/ForgotPassword"),
    meta: {
      title: "Educasis - Recuperar Senha"
    }
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: () => import("../views/ResetPassword.vue"),
    meta: { title: "Educasis - Alterar Senha" }
  }
];
