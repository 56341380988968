<template>
  <div id="app">
    <loader />
    <router-view />
  </div>
</template>

<script>
import Loader from "./views/Loader";
import { useYearStore } from "./store/year";
import { getToken } from "./services";
import User from "@/modules/auth/models/User";

export default {
  name: "App",
  components: {
    Loader,
  },
  data() {
    return {
      yearStore: useYearStore(),
    };
  },
  watch: {
    user: {
      handler: function async(newVal) {
        if (newVal?.id && getToken())
          this.yearStore.setSelectedYear(newVal?.last_year_access);
      },
      deep: true,
    },
  },
  async mounted() {
    // minimize mobile menu button
    $(document).on("click", "#toggle_btn", function() {
      if ($("body").hasClass("mini-sidebar")) {
        $("body").removeClass("mini-sidebar");
        $(".subdrop + ul").slideDown();
      } else {
        $("body").addClass("mini-sidebar");
        $(".subdrop + ul").slideUp();
      }
      return false;
    });
  },
  computed: {
    user: () => User.query().first(),
  },
};
</script>
