import { createStore } from "vuex";
import VuexORM from "@vuex-orm/core";
import axios from "axios";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import { getToken } from "@/services";
import Auth from "@/modules/auth/models/Auth";
import User from "@/modules/auth/models/User";
import Frequency from "@/modules/teachers/frequency/models/Frequency";
import LessonPlanning from "@/modules/teachers/planning/models/LessonPlanning";
import Institution from "@/models/Institution";
import RegistrationOcurrenceDisciplinary from "@/modules/teachers/occurrence-disciplinary/models/RegistrationOcurrenceDisciplinary";
import LessonPlanningAee from "@/modules/teachers/aee/planning/models/LessonPlanningAee";
import ActivityRegister from "@/modules/teachers/activity/activity-register/models/ActivityRegister";
import RecordAee from "../modules/teachers/aee/record-aee/models/RecordAee";
import ServiceAee from "../modules/teachers/aee/service-aee/models/ServiceAee";
import AbsentDelayTime from "@/modules/teachers/class-replacement/model/AbsentDelayTime";
import Notification from "../modules/notifications/models/Notification";
import UserType from "@/modules/settings/user-type/model/UserType";
import Instance from "@/modules/bot/model/Instance";

VuexORM.use(VuexORMAxios, {
  axios,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
  baseURL: process.env.BASE_URL,
});
const database = new VuexORM.Database();

database.register(Auth);
database.register(User);
database.register(Frequency);
database.register(LessonPlanning);
database.register(Institution);
database.register(RegistrationOcurrenceDisciplinary);
database.register(LessonPlanningAee);
database.register(ActivityRegister);
database.register(RecordAee);
database.register(ServiceAee);
database.register(AbsentDelayTime);
database.register(Notification);
database.register(UserType);
database.register(Instance);

export const store = new createStore({
  plugins: [VuexORM.install(database)],
});
