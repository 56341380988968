import { defineStore } from "pinia";

export const useNotificationNewsStore = defineStore("notificationNews", {
  state: () => ({
    showNotification: false
  }),
  actions: {
    setShowNotification(notification) {
      this.showNotification = notification;
    }
  }
});
