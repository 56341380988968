import { Model } from "@vuex-orm/core";
import { getToken } from "@/services";
require("regenerator-runtime/runtime");

export default class Institution extends Model {
  static entity = "institution";

  static fields() {
    return {
      cod_instituicao: this.attr(null),
      ref_usuario_cad: this.attr(""),
      ref_sigla_uf: this.attr(null),
      city: this.attr(null),
      address: this.attr(null),
      complement: this.attr(null),
      responsible_name: this.attr(null),
      institution_name: this.attr(null),
      group_curricular_component: this.attr(null),
      educacenso_date: this.attr(null),
      allow_plan_contents: this.attr(null),
      mandatory_daily_record_activity: this.attr(null),
      use_lesson_planning: this.attr(null),
      allow_plan_aee_contents: this.attr(null),
      mandatory_daily_record_aee_activity: this.attr(null),
      use_aee_lesson_planning: this.attr(null),
      check_time_table: this.attr(null),
      uses_alternated_saturday: this.attr(null),
      record_aee_individual: this.attr(null),
      uses_period_planning: this.attr(null),
      uses_unity_planning: this.attr(null),
      uses_annual_planning: this.attr(null),
      allow_recovery_class: this.attr(null),
      allow_edit_frequency: this.attr(null)
    };
  }

  static async getInstitution() {
    await this.api().get(`/institution`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
