import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class Notification extends Model {
    static entity = 'notifications';

    static fields() {
        return {
            data: this.attr({}),
            meta: this.attr({})
        }
    }

    static async getNotification(pag = 1, filters) {
        await this.api()
            .get(`/notifications?page=${pag || 1}`, {
                headers: {
                    Authorization: `Bearer ${ getToken() }`
                },
                params: {
                    filters
                }
            })
    }
}