<template>
  <li class="nav-item dropdown noti-dropdown">
    <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
      <i class="far fa-bell"></i>
      <span class="badge text-info" v-if="unread != 0">{{ unread }}</span>
    </a>
    <div class="dropdown-menu notifications">
      <div class="topnav-dropdown-header">
        <span class="notification-title">Notificações</span>
        <a href="javascript:void(0)" class="clear-noti" v-if="unread != 0" @click="markAllRead"> Marcar todas como lidas </a>
      </div>
      <div class="noti-content">
        <ul class="notification-list">
          <li class="notification-message" v-for="(notification, index) in topNotifications" :key="index">
            <router-link
              :to="{ name: 'notifications.list' }"
              @click="read(notification.id)"
            >
              <div class="media d-flex">
                <!-- <span class="avatar avatar-sm flex-shrink-0">
                  <img class="avatar-img rounded-circle" alt="User Image" src="../assets/img/profiles/avatar-02.jpg">
                </span> -->
                <div class="media-body flex-grow-1">
                  <p class="noti-details">
                    <span :class="{ 'bold-text': !notification.read_at }" class="noti-title">
                    {{ notification.text }}
                    </span>
                  </p>
                  <p class="noti-time"><span class="notification-time">{{ notification.created_at }}</span></p>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="topnav-dropdown-footer">
        <router-link :to="{ name: 'notifications.list' }">Ver todas as notificações</router-link>
      </div>
    </div>
  </li>
</template>

<script>
import Notification from '../modules/notifications/models/Notification';
import http, { getToken } from "@/services";

export default {
  name: 'Notifications',
  data() {
    return {
      unread: 0
    }
  },
  mounted() {
    this.getNotification();
    this.getCountUnread();
  },
  methods: {
    async getNotification() {
      await Notification.getNotification(1, ['notification']);
    },

    async getCountUnread() {
      let { data } = await http.get('/notifications/unread', {
        headers: {
          Authorization: `Bearer ${ getToken() }`
        }
      });
      this.unread = data;
    },

    async read(id) {
       await http.put(`/notifications/read/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${ getToken() }`
        }
      });
      this.getCountUnread();
    },

    async markAllRead() {
      await http.put('/notifications/all-read', {}, {
        headers: {
          Authorization: `Bearer ${ getToken() }`
        }
      });
      await this.getNotification();
      
      this.unread = 0;
    },
    /* Método responsável para routear dinâmicamente as notificações */
    // link(type, register, registration) {
    //   const routeMapping = {
    //     1: 'notifications.list',
    //     5: 'teacher.planning.edit',
    //     7: 'teacher.aee.planning.edit',
    //     8: 'teacher.aee.service-aee.edit',
    //     9: 'teacher.frequencies.edit',
    //   };
    //   return routeMapping[type] ? { name: routeMapping[type], params: { id: register, type: type, registration: registration } } : '';
    // },
  },
  computed: {
    getAllNotifications() {
      return Notification.all();
    },
    notifications() {
      let notifications = Notification.query().all();
      return (notifications.length > 1 && notifications.length !== 0) ? Notification.query().last()?.data : Notification.query().first()?.data;
    },
    topNotifications() {
      return this.notifications ? this.notifications.slice(0, 5) : [];
    }
  },
}
</script>
<style>
.bold-text {
  color: #2BB5FA !important;
}
</style>