import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class LessonPlanning extends Model {
    static entity = 'lessons-planning';

    static fields() {
        return {
            data: this.attr({}),
            meta: this.attr({})
        }
    }

    static async getLessonsPlanning(pag = 1, filters) {
        await this.api()
            .get(`/lesson-planning?page=${pag || 1}`, {
                headers: {
                    Authorization: `Bearer ${ getToken() }`
                },
                params: {
                    filters
                }
            })
            .then(async () => {
                let result = this.query().all();
                if (result.length > 1) {
                    let first = this.query().first();
                    await this.delete(first.$id);
                }
            });
    }

    static async saveLessonPlanning(data) {
        await http.post('lesson-planning', { data }, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        })
    }

    static async updateLessonPlanning(data, id) {
        await http.put(`lesson-planning/${id}/update`, { data }, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        })
    }

    static async getLessonPlanningById(id) {
        const { data } = await http.get(`lesson-planning/${id}`, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        });
        
        return data.data;
    }

    static async deleteLessonPlanning(id) {
        const result = await http.delete(`lesson-planning/${id}/delete`, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        })
    }

    static async getValidateLessonsPlanning(pag = 1, filters) {
        await this.api()
            .get(`/validate-lesson-planning?page=${pag || 1}`, {
                headers: {
                    Authorization: `Bearer ${ getToken() }`
                },
                params: {
                    filters
                }
            })
    }

    static async updateValidationLessonPlanning(data, id) {
        await http.put(`validate-lesson-planning/${id}/update`, { data }, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        })
    }
}
