import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class ServiceAee extends Model {
  static entity = 'service_aee';

  static fields() {
    return {
      data: this.attr({}),
      meta: this.attr({})
    }
  }

  static async getServiceAee(pag = 1, filters) {
    await this.api().get(`/service-aee?page=${pag || 1}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      },
      params: {
        filters
      }
    })
  }

  static async saveServiceAee(data) {
    await http.post('/service-aee', { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
  }

  static async getServiceAeeById(id) {
    const { data } = await http.get(`/service-aee/${id}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
    return data.data;
  }

  static async updateServiceAee(id, data) {
    await http.put(`/service-aee/${id}/update`, { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
  }

  static async deleteServiceAee(id) {
    await http.delete(`/service-aee/${id}/delete`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    })
  }

  static async getValidateServiceAee(pag = 1, filters) {
    await this.api()
      .get(`validate-service-aee?page=${pag || 1}`, {
        headers: {
          Authorization: `Bearer ${ getToken() }`
        },
        params: {
          filters
        }
      })
  }

  static async updateValidationServiceAee(data, id) {
    await http.put(`/validate-service-aee/${id}/update`, { data }, {
        headers: {
            Authorization: `Bearer ${ getToken() }`
        }
    });
}
}