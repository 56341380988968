import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class RegistrationOcurrenceDisciplinary extends Model {
  static entity = 'matricula_ocorrencia_disciplinar';

  static fields() {
    return {
      data: this.attr({}),
      meta: this.attr({})
    }
  }

  static async getOccurrenceDisciplinaries(pag = 1, filters) {
    await this.api().get(`/registration-occurrence-disciplinary?page=${pag}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      },
      params: {
        filters
      }
    })
  }

  static async saveOccurrenceDisciplinary(data) {
    await http.post('/registration-occurrence-disciplinary', { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
  }  

  static async getOccurrenceById(id) {
    const { data } = await http.get(`/registration-occurrence-disciplinary/${id}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
    return data.data;
}

  static async updateOccurrenceDisciplinary(id, data) {
    await http.put(`/registration-occurrence-disciplinary/${id}/update`, { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
}

  static async deleteOccurrenceDisciplinary(id) {
    await http.delete(`/registration-occurrence-disciplinary/${id}/delete`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    })
  }
}