import { Model } from "@vuex-orm/core";
import http, { getToken, TOKEN_NAME } from "@/services";
import "regenerator-runtime/runtime";
import Institution from "@/models/Institution";
import User from "./User";

export default class Auth extends Model {
  static entity = "auth";

  static fields() {
    return {
      registration: this.string(""),
      password: this.string(""),
      user_id: this.number(null)
    };
  }

  static async login(registration, password) {
    const { response } = await this.api().post("/auth/login", {
      registration,
      password
    });
    const { token } = response.data;
    localStorage.setItem(TOKEN_NAME, token);
  }

  static async recoveryPassword(email) {
    await this.api().post('/auth/recovery-password', { email, origin: window.location.origin });
  }

  static async resetPassword(token, password) {
    await this.api().post('/auth/reset-password', { token, password })
  }

  static logout() {
    User.query().deleteAll();
    Institution.query().deleteAll();
    this.api()
      .post(
        "/auth/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(() => {
        localStorage.removeItem("selectedYear");
        localStorage.removeItem(TOKEN_NAME);
      });
  }

  static async getImage() {
    const { data } = await http.get("/get-image");
    return data.ieducar_image;
  }
}
