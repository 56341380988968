<template>
  <div>
    <!-- Loader com a imagem -->
    <div id="loader-wrapper">
      <div class="loader">
        <img
          src="@/assets/img/logo-tecsis.png"
          alt="Logo"
          id="loader"
          class="logo"
          :class="{ blinking: logoVisible }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      logoVisible: true, // Inicialmente a logo está visível
    };
  },
  beforeCreate() {
    $(window).on("load", () => {
      $("#loader")
        .delay(500)
        .fadeOut("slow");
      $("#loader-wrapper")
        .delay(500)
        .fadeOut("slow");
      $("body")
        .delay(500)
        .css({ overflow: "visible" });
    });

    // Usando setInterval para fazer a logo piscar
    setInterval(() => {
      this.logoVisible = !this.logoVisible;
    }, 500); // Intervalo de 0.2 segundos (200 milissegundos)
  },
};
</script>

<style scoped>
#loader-wrapper {
  background-color: #fff;
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 30vh;
  width: auto;
  transition: opacity 0.2s ease; /* Transição suave de 0.2 segundos */
}

.blinking {
  opacity: 0; /* Inicialmente a logo está invisível */
}

/* Adicione outros estilos conforme necessário */
</style>
