import { defineStore } from "pinia";
import http, { getToken } from "@/services";

export const useNewsStore = defineStore("news", {
  state: () => ({
    data: {},
    meta: {}
  }),
  actions: {
    async getNews(filters, pag = 1) {
      await http
        .get("https://abhomologacao-back.educasis.tec.br/api/v1/news", {
          headers: {
            "news-api-key":
              "c922f2ed7da749cd5d92084aad9cf01bb705e458b30fa0eb47a8f966471f2674"
          },
          params: {
            page: pag,
            filters
          }
        })
        .then(({ data }) => {
          this.setData(data);
        });
    },
    async saveNews(data) {
      await http.post(
        "news",
        { data },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data"
          }
        }
      );
    },
    async getNewsById(id) {
      const { data } = await http.get(`news/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      });

      return data.data;
    },
    async updateNews(id, data) {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("id", id);
      formData.append("date", data.date);
      formData.append("title", data.title);
      formData.append("month", data.month);
      formData.append("year", data.year);
      if (data.files.length) {
        data.files.forEach((file, index) => {
          formData.append(`files[${index}]`, file);
        });
      }
      await http.post(`/news/update`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data"
        }
      });
    },
    async delete(id) {
      await http.delete(`news/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      });
    },
    setData({ data, meta }) {
      this.data = data;
      this.meta = meta;
    }
  }
});
