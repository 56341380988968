export default [
    {
        path: '/app/news',
        component: () => import('../views/index.vue'),
        meta: {
            title: 'Educasis - Novidades',
            requiresAuth: true
        },
        children: [
            {
                path: '/app/news/',
                name: 'news.list',
                component: () => import('../views/List.vue'),
            },
            {
                path: '/app/news/create',
                name: 'news.create',
                component: () => import('../views/Create.vue'),
            }
        ]
    }
];
