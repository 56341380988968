export default [
  {
    path: '/app/notifications',
    component: () => import('../views'),
    meta: {
      title: 'Educasis - Notificações',
      requiresAuth: true
    },
    children: [
      {
        path: '/app/notifications',
        name: 'notifications.list',
        component: () => import('../views/List'),
      }
    ]
  }
];
