import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class ActivityRegister extends Model {
  static entity = 'activities';

  static fields() {
    return {
      data: this.attr({}),
      meta: this.attr({})
    }
  }

  static async getActivities(pag = 1, filters) {
    await this.api().get(`/activity?page=${pag}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      },
      params: {
        filters
      }
    })
  }

  static async saveActivity(data) {
    await http.post('/activity', { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
  }

  static async getActivityById(id) {
    const { data } = await http.get(`/activity/${id}`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
    return data.data;
  }

  static async updateActivity(id, data) {
    await http.put(`/activity/${id}/update`, { data }, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    });
  }

  static async deleteActivity(id) {
    await http.delete(`/activity/${id}/delete`, {
      headers: {
        Authorization: `Bearer ${ getToken() }`
      }
    })
  }
}