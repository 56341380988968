<template>
  <!-- Logo -->
  <div class="header-left">
    <router-link :to="{ name: 'app.main' }" class="logo">
      <img src="../assets/img/educasis-transparent.png" alt="Educasis" />
    </router-link>
    <router-link :to="{ name: 'app.main' }" class="logo logo-small">
      <img src="../assets/img/logo-small-educasis.png" alt="Educasis" />
    </router-link>
  </div>
  <!-- /Logo -->
</template>
