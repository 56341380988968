import { defineStore } from "pinia";

export const useBreadcrumbStore = defineStore("breadcrumb", {
    state: () => ({
        breadcrumbs: []
    }),
    actions: {
        setBreadcrumbs(breadcrumbs) {
            this.breadcrumbs = breadcrumbs;
        },
    }
});
