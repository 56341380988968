export default [
  {
    path: "/app/bot",
    component: () => import("../views/index.vue"),
    meta: {
      title: "Educasis - Configurações",
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "bot.index",
        component: () => import("../views/List.vue"),
      },
      {
        path: ":id",
        name: "bot.edit",
        component: () => import("../views/Edit.vue"),
      },
    ],
  },
];
