<template>
  <!-- Page Header -->
  <div class="page-header d-inline-block m-t-10">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="page-title">{{ page_title }}</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link v-if="!title" to="/app">
              <i class="fas fa-home text-info"></i>
              Início
            </router-link>
          </li>
          <slot name="breadcrumb_list"></slot>
        </ul>
      </div>
      <div class="col-auto text-end float-end ms-auto">
        <slot name="page-header-icon"></slot>
      </div>
    </div>
  </div>
  <!-- /Page Header -->
</template>

<script>
import { useBreadcrumbStore } from "@/store/breadcrumb";

export default {
  name: "DefaultPageHeader",
  props: {
    page_title: {
      required: false,
      type: String,
    },
    title: {
      require: false,
      type: Boolean,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    breadcrumbStore: useBreadcrumbStore(),
  }),
  mounted() {
    this.breadcrumbStore.setBreadcrumbs(this.breadcrumbs);
  },
  // watch: {
  //   breadcrumbs: {
  //     handler: function(newVal) {
  //       console.log(newVal);
  //     },
  //     deep: true,
  //   },
  //   breadcrumbsLength(newVal) {
  //     console.log(newVal);
  //     // if (newVal > 0) {
  //     //   this.breadcrumbStore.setBreadcrumbs(this.breadcrumbs);
  //     // }
  //   },
  // },
  // computed: {
  //   breadcrumbsLength() {
  //     return this.breadcrumbs.length || 0;
  //   },
  // },
};
</script>
