import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";

export default class UserType extends Model {
    static entity = 'user-type';

    static fields() {
        return {
            data: this.attr({}),
            meta: this.attr({})
        }
    }

    static async getUserTypes(pag = 1, filters) {
        await this.api()
            .get(`/user-type?page=${pag || 1}`, {
                headers: {
                    Authorization: `Bearer ${ getToken() }`
                },
                params: {
                    filters
                }
            })
            .then(async () => {
                let result = this.query().all();
                if (result.length > 1) {
                    let first = this.query().first();
                    await this.delete(first.$id);
                }
            });
    }

    static async saveUserType(data) {
        await http.post('/user-type', { data }, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        });
    }

    static async getUserTypeById(id) {
        const { data } = await http.get(`user-type/${id}`, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        });

        return data.data;
    }

    static async updateUserType(data, id) {
        await http.put(`/user-type/${id}`, { data }, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        });
    }

    static async deleteUserType(id) {
        await http.delete(`user-type/${id}`, {
            headers: {
                Authorization: `Bearer ${ getToken() }`
            }
        })
    }
}
